import classNames from "classnames"
import { useTemplate } from "@/lib/helpers/template"
import Block from "@/components/wrappers/block"
import styles from "./heading.module.css"

function Heading({ block, settings }) {
  const { classList, noStyling, theme } = block.attrs
  const template = useTemplate()
  const classes = [
    styles.heading,
    theme,
    template,
    classList,
    settings?.innerAlign
  ]

  let width = settings?.width ?? "column"
  let gap = "none"

  if (noStyling) {
    width = gap = null
  }

  return (
    <Block
      block={block}
      width={width}
      gap={gap}
      noStyling={block.attrs.noStyling}
      className={classNames(classes)}
      innerHtml={block.html}
    />
  )
}

export default Heading
